if ($('#worldmap').length) {

    var mapRef = Snap('#worldmap');
    var dot = mapRef.select('#theDot');

    var theWorldMap = {

        runMap: false,

        circle: $('#theDot circle'),

        dotbbox: dot.getBBox(),

        mapAnimations: [],

        i: 0,

        worldmapStart: function() {
            console.log('worldmap start');
            theWorldMap.runMap = true;
            theWorldMap.circle.removeClass('dot-hidden');
            theWorldMap.i = 0;
            theWorldMap.animateDot(theWorldMap.mapAnimations[theWorldMap.i]);
        },

        animateDot: function (a) {
            console.log('animate');
            Snap.animate(0, a.path_length, function( step ) {
                moveToPoint = Snap.path.getPointAtLength( a.SVGpath, step );
                dot.transform('translate(' + moveToPoint.x + ',' + moveToPoint.y + ') rotate('+ (moveToPoint.alpha - 90)+', ' + theWorldMap.dotbbox.cx + ', ' + theWorldMap.dotbbox.cy + ')');
            },2000, mina.easeinout, function() {
                theWorldMap.i++;
                if (theWorldMap.i == theWorldMap.mapAnimations.length && theWorldMap.runMap) {
                    theWorldMap.i = 0;
                }
                if (theWorldMap.runMap) {
                    theWorldMap.animateDot(theWorldMap.mapAnimations[theWorldMap.i]);
                }
            });
        },

        worldmapCheck: function () {
            if ($('.swiper-slide-active').hasClass('worldmap')) {
                $('#banner').addClass('white-arrows');
            } else {
                $('#banner').removeClass('white-arrows');
            }
        }
    }

    var mapPaths = $('g#locations > path').map(function(){
        return $(this).attr('d');
    });
    mapPaths = jQuery.makeArray(mapPaths);
    mapPaths.forEach(function(element) {
        var SVGpath = mapRef.path(element).attr({ 'fill': 'none', 'stroke': 'none'});
        var path_length = Snap.path.getTotalLength(SVGpath);
        var last_point = SVGpath.getPointAtLength(path_length);
        theWorldMap.mapAnimations.push({
            SVGpath: SVGpath,
            path_length: path_length,
            last_point: last_point
        });
    }, this);
    theWorldMap.worldmapStart();

}


$(function () {

    // nav
    $('.expand-nav').on('click', function (e) {
        $('.mobile-nav-wrap').fadeToggle(100);
        $('body').toggleClass('nav-expanded');
        if ($('.overlay').length) {
            $('.overlay').fadeOut(200, function () {
                $(this).remove();
            });
        } else {
            var overlay = $('<div class="overlay"></div>');
            overlay.appendTo('body').height($(document).height()).fadeIn(100);
        }
        e.preventDefault();
    });

    $(document).on('click', '.overlay', function () {
        $('.mobile-nav-wrap').slideUp('300');
        $('.overlay').remove();
        $('body').removeClass('nav-expanded');
    });

    $(document).on('click', '.subnav-expand', function (e) {
		e.preventDefault();
		if ($(window).width() < 768) {
			$(this).siblings('ul').slideToggle(200);
		}
	});

    var initialWidth = $(window).width();
    $(window).on('resize', function() {
        if ($(window).width() != initialWidth) {
            $('body').removeClass('nav-expanded');
            $('.overlay').remove();
            if ($(window).width() > 767) {
                $('.mobile-nav-wrap').show();
                $('header nav.main li ul').css('display','none');
            } else {
                $('.mobile-nav-wrap').hide();
            }
        }
    });

    // wrap videos in responsive box
    $("main section.content iframe, main section.intro iframe").wrap('<div class="embed-wrap"></div>');

    // home banner carousel
    if ($('#banner').length) {
        var bannerSwiper = new Swiper('#banner', {
            direction: 'horizontal',
            autoplay: 7000,
            autoHeight: true,
            speed: 900,
            a11y: true,
            pagination: '.swiper-pagination',
            paginationType: 'bullets',
            paginationClickable: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            loop: true,
            onInit: function() {
                if ($('#worldmap').length) {
                    theWorldMap.worldmapCheck();
                }
            }
        });
        bannerSwiper.on('slideChangeEnd', function () {
            if ($('#worldmap').length) {
                theWorldMap.worldmapCheck();
            }
        });
    }
    if ($('#home-clients').length) {
        var clientsSwiper = new Swiper('#home-clients', {
            direction: 'horizontal',
            loop: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
        });
    }

    var banner = document.getElementById('banner');
    var bannerVideo = document.getElementById('banner-video');
    if (bannerVideo) {
        var player = new Vimeo.Player(bannerVideo);
        player.on('play', function() {
            bannerSwiper.stopAutoplay();
            $(banner).addClass('video-playing');
        });
        player.on('pause', function() {
            bannerSwiper.startAutoplay();
            $(banner).removeClass('video-playing');
        });
        player.on('ended', function() {
            bannerSwiper.startAutoplay();
            $(banner).removeClass('video-playing');
        });
    }

    // team filter
    if ($('#team-select').length) {
        memberMap = $('section.location-members .member').map(function() {
            return $(this).data('id');
        });
        memberMap = jQuery.makeArray(memberMap);
        memberHTML = $('section.location-members .member').map(function() {
            return this.outerHTML;
        });
        memberHTML = jQuery.makeArray(memberHTML);
        $('#team-select').on('change', function() {
            teamContainer = $('section.location-members .wrap');
            teamContainer.empty();
            if ($('#team-select :selected').val() === 'All') {
                console.log('All');
                for (var i = 0; i < memberHTML.length; i++) {
                    teamContainer.append(memberHTML[i]);
                }
            } else {
                var showMembersString = $('#team-select :selected').data('ids') + '';
                var showMembers = showMembersString.split(',');
                showMembersKeys = [];
                showMembers.forEach(function(e) {
                    showMembersKeys.push(memberMap.indexOf(parseInt(e)));
                }, this);
                showMembersKeys.forEach(function(e) {
                    teamContainer.append(memberHTML[e]);
                }, this);
            }
        });
    }

    // validate contact form
    if ($("#contact-form").length) {
        $("#contact-form").validate();
        $("#other-interest").on('focus', function() {
            $('#interests input').prop('checked',false);
            $('#int-other').prop('checked',true);
        });
    }

    // location accordion on mobile
    $('.mobile-location-list li > a').on('click', function(e){
        $(this).toggleClass('active');
        $(this).parent('li').find('.inner').slideToggle();
        e.preventDefault();
    });

    $('.mobile-location-list .btn').on('click', function(e){
        $('#branch option:selected').prop("selected",null);
        $('#branch option[value="' + $(this).attr('data-title') + '"]').prop("selected","selected");
    });

    // closest office click to call
    if ($('#click-to-call').length || $('#branch').length) {
        var cookieLocation = Cookies.get('kp_location');
        if (typeof cookieLocation === "undefined" ) {
            geoLocate();
        } else {
            var closest = JSON.parse(cookieLocation);
            updateClicktoCall(closest);
        }
    }


    $('#form-input-datePicker').datetimepicker({
        dayOfWeekStart : 1,
        format:'d.m.Y  H:i',
        lang:'en',
        disabledDates:['1986/01/08','1986/01/09','1986/01/10'],
        startDate:	'2017/01/01',
        onGenerate:function( ct ){
            $(this).find('.xdsoft_date.xdsoft_weekend')
                .addClass('xdsoft_disabled');
        },
        weekends:['01.01.2014','02.01.2014','03.01.2014','04.01.2014','05.01.2014','06.01.2014'],
        timepicker:true
    });


    // Statistics
    $('.stat strong').counterUp({
        delay: 10, // the delay time in ms
        time: 1000 // the speed time in ms
    });



    $(window).on('resize load',function(){
        var bannerHeight = $('#banner').height();
        var bannerContentHeight = $('.banner-no-1').one().outerHeight();

        if ( $('.banner-no-1').length){

            var positionSwiperPagination = bannerHeight - bannerContentHeight - 30;
            $('.swiper-pagination').css({'top': positionSwiperPagination + 'px'});

        }
    })

    $('.question').on('click', function(e){
        e.preventDefault();
        $(this).next('.answer').toggleClass('close');
    })

    // $(window).on('resize load',function() {
    // 	$('.stock-ticketer').animate({
    //             height: '50px'
    //         })
    //     $("#shareprice").endlessRiver({
    //         buttons: false
    //     });
    // });


    // if ($('.form-pages form').length){
    // 	$('.form-pages form').submit( function (e ) {
    // 		e.preventDefault();
    //
    // 		$('body').addClass('overlay-active');
    //        $("html, body").animate({ scrollTop: "0px" });
    //
    // 		var data = $(this).serialize();
    // 		$.ajax({
    // 			method: 'POST',
    // 			url: '/',
    // 			data: data,
    // 			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    // 		})
    // 			.success(function(data) {
    // 				if ($('.overlay-wrapper').length){
    // 					$('.overlay-wrapper').removeClass('hide');
    // 				}
    // 			})
    // 			.error(function(data) {
    // 				console.log('error', data);
    // 			});
    // 	});
    // }
    //
    // $('.close-thik').on('click touch', function(){
    //
    //    $('.overlay-wrapper').addClass('hide');
    //    $('body').removeClass('overlay-active');
    // })

    var currentYear = new Date().getFullYear();

    displayingLists();

    function displayingLists(){
        $('[class^="year-"]').hide();
        $('[class^="year-'+ currentYear +'"]').show();
    }


    $('.previous-year').on('click', function(e){
        e.preventDefault();
        currentYear = currentYear - 1;

        displayingLists();
    })

    $('.next-year').on('click', function(e){
        e.preventDefault();
        currentYear = currentYear + 1;

        displayingLists();
    })





    if ($(".form-prospectus").length){
        $("form").on({
            submit: function () {
                var $self = $(this);
                $(".alert.alert-success").remove();
                $("button[type=submit]", $self).prop("disabled", true);

                $.ajax({
                    url: $("input[name=action]", $(this)).val(),
                    type: "post",
                    dataType: "json",
                    data: $(this).serialize(),
                    success: function (response) {
                        $('.btn-download-prospectus')[0].click();
                        //$("ul.errors.help-block", $self).remove();
                        //$(".has-error", $self).removeClass("has-error");

                        if (response.success && response.finished) {
                            $self[0].reset();

                            // var $successMessage = $("<div>", {"class": "alert alert-success"})
                            // 	.append("<p>Form submitted successfully</p>", {"class": "lead"});
                            //
                            // $self.before($successMessage);

                        }
                        // else if (response.errors) {
                        // 	for (var key in response.errors) {
                        // 		if (!response.errors.hasOwnProperty(key)) continue;
                        // 		var messages = response.errors[key];
                        // 		var $errors  = $("<ul>", {"class": "errors help-block"});
                        // 		for (var i = 0; i < messages.length; i++) {
                        // 			var $li = $("<li>");
                        // 			$li.html(messages[i]);
                        // 			$errors.append($li);
                        // 		}
                        //
                        // 		var $input = $("*[name=" + key + "], *[name='" + key + "[]']");
                        //
                        // 		const $formGroup = $input.parents(".form-group");
                        // 		$formGroup.addClass("has-error");
                        //
                        // 		$formGroup.append($errors);
                        // 	}
                        // }

                        $("button[type=submit]", $self).prop("disabled", false);
                    }
                });

                return false;
            }
        });

    }

    // Popup

    if ($('.popup').length > 0) {

      if (!Cookies.get('country-select')) {
        setTimeout(function () {
          $('.country-select').fadeIn(250);
          $('body').addClass('modal-open');
        }, 1000);

        // Show disclaimer for first country
        var countryClass = '.' + $('.country-select select option')[0].value;
        $('.popup-body ' + countryClass).show();
      }

      $('.country-select button').on('click', function () {
        $('.country-select').fadeOut(200);
        $('body').removeClass('modal-open');
        Cookies.set('country-select', $('.country-select select').val(), { expires: 28 });
      });

      $('.country-select select').on('change', function () {
        $('.popup-body .content').hide();
        $('.popup-body .' + $(this).val()).show();
      });
    }

    $(document).ready(function(){

        if(window.location.pathname == '/fact-sheet-thank-you' ){
            $('#download')[0].click();

        }
    
        if(window.location.pathname == '/memorandum-thank-you' ){

            $('#download')[0].click();
        }


        if($('.tab-section').length){
        $('.tab-section ul.tabs li').click(function(){
            var tab_id = $(this).attr('data-tab');
    
            $('ul.tabs li').removeClass('current');
            $('.tab-content').removeClass('current');
    
            $(this).addClass('current');
            $("#"+tab_id).addClass('current');
        })
    }


    if($('.download-btn-group').length){
        $('.download-btn-group ul.btn-group li a').click(function(){
            var tab_id = $(this).attr('data-tab');
    
            $('ul.btn-group li').removeClass('active');
            $('.tab-content .tab-pane').removeClass('active');
    
            $(this).addClass('active');
            $(tab_id).addClass('active');
        })
    }
    if($('.snap-select-toggle').length){
    $( ".snap-select-toggle" ).click(function() {
        $( this ).toggleClass( "active" );
      $('.snap-dropdown').toggle();
      });
    }

    if($('.snap-dropdown').length){

        $( ".snap-dropdown li a" ).each(function( ) {
            var url = $(this).attr('href').trim();
            console.log(url);
            var currentUrl = window.location.href;
              console.log(currentUrl);
            if(url == currentUrl){
                console.log('true');
        
                $(this).closest('li').hide();
            }
        
        });
        
        }

        if($('.blog-ul').length){

            $( ".blog-ul" ).each(function( ) {
  
                if(!$(this).find('ul').has( "li" ).length){
                    $(this).closest('.col-box').hide();
                }
            
            });
            
            }
    

    
    })


    

});
